import React from 'react';
import './assets/css/general.css'
import './assets/css/flex-columns.css'
import './assets/css/navigation.css'
import './assets/css/parallax.css'
import './assets/css/app-header.css'
import './assets/css/app-body.css'
import './assets/css/app-footer.css'
import Header from './components/Header';
import Routing from './routing/Routing';
import Footer from './components/Footer';

const App = () => (
        <div className='App'>
                <Header />
                <Routing />
                <Footer />
        </div>
);

export default App;