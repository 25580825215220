const Welcome = () => {

    return (
        <div>
            <div className={'body-text'}>
                <header>
                    <h2>Welcome,</h2>
                    {/*<span className="byline">Integer sit amet pede vel arcu aliquet pretium</span>*/}
                </header>
                {/*<a href="#" className="image full"><img src={pic07} alt="" /></a>*/}
                <p>
                    When I started jjwheatley.com, it was designed to showcase my skills as a software
                    engineer & web developer. Since then, I've come to realize that in addition to
                    'what I do', I want to share a bit more of 'who I am'. Every one of us is so much more
                    than the job we do or the skills we develop, that it seems almost silly to limit a
                    personal website to the purely professional. This is why I'm updating jjwheatley.com
                    & also why you might notice a few changes, since your last visit.
                </p>
                <p>
                    For the moment, I’ve divided these two sections into professional for ‘what I do’ &
                    personal for ‘who I am’. The former contains details of my activities as a professional
                    software engineer. While the latter provides an insight into some the side-projects
                    & hobbies I pursue in my free time. Hope you enjoy it.
                </p>
                <p>
                    Warm regards,<br />
                    J.J.Wheatley
                </p>
            </div>
        </div>
    )
};

export default Welcome;