import Navigation from "../routing/Navigation";

const Header = () => (
    <div id="header">
        <div id="logo">
            <h1><div>J.J.Wheatley</div></h1>
        </div>
        <div className="flex-box">
            <Navigation />

        </div>
    </div>
)

export default Header