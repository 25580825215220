import Links from '../links';
import { ExternalLink } from 'react-external-link';

const Professional = () => {
    const subsiteCerts = <ExternalLink href={Links.subSites.certs}>certs.jjwheatley.com</ExternalLink>
    const subsiteCode = <ExternalLink href={Links.subSites.code}>code.jjwheatley.com</ExternalLink>
    const linkedIn = <ExternalLink href={Links.subSites.code}>LinkedIn</ExternalLink>

    return (
        <div>
            <div className={'body-text'}>
                <h2>The Beginning</h2>
                <div className={'paragraph'}>
                    I started out writing software for a major player in the laser industry, back in the UK. Until, in 2017
                    an opportunity to work abroad brought me to the Netherlands. Since then, I've led international teams of
                    developers, worked on ATM software, built audience interaction systems and generally had a great time
                    doing all the techy things I always dreamed of doing.
                </div>
                <div className={'paragraph'}>
                    For most of this time I worked in some variant of the LAMP stack (that’s Linux, Apache, MySQL & PHP).
                    More recently, I've become interested in the world of React & Node.js. So, that’s what I currently
                    work in.
                </div>
                <div className={'paragraph'}>
                    This brings me to one of things I love about working in software, which is the continual learning
                    involved with working in this field. I’ve always experienced learning as being fun & something that
                    even brings me a degree of happiness. So, I feel grateful to be in a career where learning is part of
                    the job.
                </div>
            </div>

            <div className="parallax-skills">
                <div className="display-middle">
                    <span className="display-middle-text">Developer</span>
                </div>
            </div>
            <div className={'body-text'}>
                <div className={'paragraph'}>
                    If you’re interested in the things I build, have a look at my portfolio
                    site. It's a recent addition to the family at jjwheatley.com, so it'll appreciate the traffic.
                    Check it out at {subsiteCode}.
                </div>
                <div className={'paragraph'}>
                    Of course, if you're more interested in certificates, have a look at the subdomain where I store
                    all things professional development: {subsiteCerts}
                </div>
            </div>
            <div className="parallax-contact">
                <div className="display-middle">
                    <span className="display-middle-text black">Contact</span>
                </div>
            </div>
            <div className={'body-text'}>
                <h2>Get in touch!</h2>
                <div className={'paragraph'}>
                    Everything business for me goes via {linkedIn}. So, if
                    you're interested in getting to know me better, drop me a message on there. I am one of those
                    people who genuinely enjoys getting coffee (even if it's virtual). So, let's do that.
                </div>
                <div className={'paragraph'}>
                    Lastly, if you're after a CV, you can find
                    that <a href={process.env.PUBLIC_URL + '/cv.pdf'}  target="_blank" rel="noopener noreferrer" download>here</a>.
                </div>
                <div className={'paragraph'} style={{marginBottom: '200px'}}>
                    See you soon.
                </div>
            </div>
        </div>
    )
};

export default Professional;