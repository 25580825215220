import CopyrightIcon from '@material-ui/icons/Copyright';
import moment from 'moment'

const Footer = () => (
    <div id={'footer'}>
        <div id={'socials'}>
        </div>
        <div id="copyright">
            <div className="container">
                <CopyrightIcon style={{ fontSize: 14 }} /> J.J.Wheatley 2016-{ moment().year() }
            </div>
        </div>
    </div>
)

export default Footer