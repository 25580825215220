import {NavLink} from "react-router-dom";

const Navigation = () => (
    <nav id="nav">
        <ul>
            <li><NavLink exact activeClassName="current" to='/'>Welcome</NavLink></li>
            <li><NavLink exact activeClassName="current" to='/professional'>Professional</NavLink></li>
            <li><NavLink exact activeClassName="current" to='/personal'>Personal</NavLink></li>
        </ul>
    </nav>
);

export default Navigation