import {Route, Switch} from "react-router-dom";
import Welcome from "../pages/Welcome";
import Professional from "../pages/Professional";
import Personal from "../pages/Personal";


const Routing = () => {
    return (
        <div className={'App-Body'}>
            <Switch>
                <Route exact path='/' component={Welcome}/>
                <Route exact path='/professional' component={Professional}/>
                <Route exact path='/personal' component={Personal}/>
            </Switch>
        </div>
    );
};

export default Routing