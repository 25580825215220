import BooksPic from '../assets/images/janko-ferlic-sfL_QOnmy00-unsplash.jpg';
import PodcastLogo from '../assets/images/inab-logo.jpg';
import Links from '../links';
import { ExternalLink } from 'react-external-link';

const Personal = () => {
    //Creative output:
    const subsiteCreative = <ExternalLink href={Links.subSites.creative}>creative.jjwheatley.com</ExternalLink>
    const BookList2019 = <ExternalLink href={Links.misc.readingList2019}>2019</ExternalLink>
    const BookList2020 = <ExternalLink href={Links.misc.readingList2020}>2020</ExternalLink>
    const TheBusToOrton = <ExternalLink href={Links.writtenWork.busToOrton}>The Bus to Orton</ExternalLink>

    //Podcasts
    const INAB = <ExternalLink href={Links.podcasts.inab}>Not A Buffalo</ExternalLink>
    const WhatIBelieve =  <ExternalLink href={Links.podcasts.whatIBelieve}>What I Believe</ExternalLink>
    const Modulus =  <ExternalLink href={Links.podcasts.modulus}>Modulus</ExternalLink>
    const TheWorldAccordingToWikipedia =  <ExternalLink href={Links.podcasts.theWorldAccordingToWikipedia}>The World According To Wikipedia</ExternalLink>

    //Board games:
    const TheKingIsDead = <ExternalLink href={Links.boardGames.theKingIsDead}>The King is Dead</ExternalLink>
    const NewYorkZoo = <ExternalLink href={Links.boardGames.newYorkZoo}>New York Zoo</ExternalLink>
    const SevenWondersDual = <ExternalLink href={Links.boardGames.sevenWondersDual}>7 Wonders Dual</ExternalLink>
    const Carcassonne = <ExternalLink href={Links.boardGames.carcassonne}>Carcassonne</ExternalLink>
    const TinyEpicGalaxies = <ExternalLink href={Links.boardGames.tinyEpicGalaxies}>Tiny Epic Galaxies</ExternalLink>
    const MachiKoro = <ExternalLink href={Links.boardGames.machiKoro}>Machi Koro</ExternalLink>
    const SettlersOfCatan = <ExternalLink href={Links.boardGames.settlersOfCatan}>Settlers Of Catan</ExternalLink>
    const Lindisfarne = <ExternalLink href={Links.boardGames.lindisfarne}>Lindisfarne</ExternalLink>
    const Onitama = <ExternalLink href={Links.boardGames.onitama}>Onitama</ExternalLink>
    const Jaipur = <ExternalLink href={Links.boardGames.jaipur}>Jaipur</ExternalLink>

    //Misc
    const YouTubeRealEngineering = <ExternalLink href={Links.misc.youTubeRealEngineering}>Real Engineering</ExternalLink>

    return (
        <div>
            <div className={'body-text'}>
                <h2>Who I Am</h2>
                <div className={'paragraph'}>
                    In my free time, I like to make stuff. In fact, I have a subdomain of this website, where I put that
                    stuff. It’s called {subsiteCreative}. Check it out or read on to learn more about these projects.
                </div>
            </div>

            <div className="parallax-podcasts">
                <div className="display-middle">
                    <span className="display-middle-text">Podcasts</span>
                </div>
            </div>
            <div className={'body-text'}>
                <div className={'paragraph'}>
                    I'm extremely passionate about the history & advancement of science. So much so, that I co-host a
                    science communication podcast called {INAB} (a title that our podcast continues to live up to).
                    I co-host it with my long-time friend Ben. Together, we take an optimistic look at recent breakthroughs
                    in science & technology.
                </div>
                <div className="flex-grid">
                    <img src={PodcastLogo} width={'49%'} style={{maxWidth: '350px'}} className={'col'}  alt={'podcast-logo'}/>
                    <div className={'col photo-text'}>Logo for <br /> the Podcast</div>
                </div>
                <div className={'paragraph'}>
                    I also love to listen to podcasts. A few of my favourites are:
                    <ul>
                        <li>
                            {WhatIBelieve}<br />
                            An interview podcast from Humanists UK, where they interview prominent humanists & explore
                            the role of humanism in today's world.
                        </li>
                        <li>
                            {TheWorldAccordingToWikipedia}<br />
                            A podcast from the headstuff network, that offers a glimpse into the lives of people who
                            edit wikipedia for 20+ hours a week (in addition to their day-job).
                        </li>
                        <li>
                            {Modulus}<br />
                            This one comes from the YouTube channel {YouTubeRealEngineering} & unsurprisingly deals with
                            subjects involving engineering.
                        </li>
                    </ul>
                </div>
            </div>


            <div className="parallax-books">
                <div className="display-middle">
                    <span className="display-middle-text">Books</span>
                </div>
            </div>

            <div className={'body-text'}>
                <div className={'paragraph'}>
                    In my free time, I love to read. I started tracking my new reads in late 2018 & realized that I consume
                    quite a lot of books. So, at the start of each year, I now post a list of the books I read in the
                    previous 12 months. Each title read gets a rating, along with a mini-review. You can find the lists
                    for {BookList2019} & {BookList2020} on LinkedIn.
                </div>
                <div className="flex-grid">
                    <div className={'col photo-text'}>"To read is to voyage through time." <br />~ Carl Sagan</div>
                    <img src={BooksPic} width={'49%'} style={{maxWidth: '350px'}} className={'col'}  alt={'books-pic'}/>
                </div>
                <div className={'paragraph'}>
                    Writing is also an interest of mine & I’ve had a few things published over the years. Most recently in
                    2020, when my short fiction piece {TheBusToOrton} appeared in the fabulously artsy literary
                    journal The Internet Void.
                </div>
            </div>

            <div className="parallax-boardgame">
                <div className="display-middle">
                    <span className="display-middle-text">Board Games</span>
                </div>
            </div>
            <div className={'body-text'}>
                <div className={'paragraph'}>
                    I've been really lucky to discover a lot of people in my life who like board games. Lucky because I
                    really like board games. Recently, I've been playing a lot
                    of {TheKingIsDead}, {NewYorkZoo} & {SevenWondersDual}.
                </div>
                <div className={'paragraph'}>
                    I also enjoy:
                    <ul>
                        <li>
                            {Carcassonne}: One of the first boardgames beyond stuff like Monopoly & Scrabble, that I
                            ever played.
                        </li>
                        <li>
                            {TinyEpicGalaxies}: I first played this in a bar with a total stranger & purchased it
                            online 5 minutes after the game started.
                        </li>
                        <li>
                            {MachiKoro}: A game where you build a japanese city.
                        </li>
                        <li>
                            {SettlersOfCatan}: Yes, I still use the old name & no I will not shorten it to Catan.
                        </li>
                        <li>
                            {Lindisfarne}: It's a viking game. Also, really well balanced play.
                        </li>
                        <li>
                            {Onitama}: Something like chess in the form of a card game, with a bunch of additional
                            mechanics.
                        </li>
                        <li>
                            {Jaipur}: A fast-paced 2-player card game, that can get amazingly competitive
                        </li>
                    </ul>
                </div>
                <div className={'paragraph'}>
                    If you are one of those people who is into board games. Thank you for
                    being one of those people.
                </div>
            </div>
        </div>
    )
};

export default Personal;